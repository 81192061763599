<script>
// import simplebar from "simplebar-vue";
import { menuItems } from "./horizontal-menu";
// import { state, socket  } from "@/socket";
// import io from 'socket.io-client';
import { layoutComputed, authFackMethods } from "@/state/helpers";
import axios from 'axios';
import Swal from "sweetalert2";
import modalLastActive from "@/components/modal-Last-Active";
import _ from "lodash";

/**
 * Horizontal-topbar component
 */
export default {
  components: { modalLastActive },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuItems: menuItems,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/china.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      totalNotif: 0,
      message: {
        from: 'Bagus A',
        text: 'Halo kak, internet di rumah saya su...'
      },
      userName: '-',
      searchValue: '',
      isSearchFound: null,
      countOnlineAdmin:0,
      dataLastActivity:null
    };
  },
  computed: {
    ...layoutComputed,
    // connected() {
    //   // console.log('socket', state)
    //   return state.connected;
    // }
  },
  // created () {
  //   console.log('run')
  //   // const socket = io.connect("https://socked-api.hub3.nusantara.net.id");
  //   if(!socket.connected) {
  //     socket.on('getNotif', async (args) => {
  //       console.log('socketGet', args)
  //       await this.updateNotif(args.totalNotif)
  //       console.log('updateNotif', this.totalNotif)
  //       // self.totalNotif = args.totalNotif
  //       this.message.text = args.message
  //       this.message.from = args.profileName
  //       this.$root.$emit('chatSocket', args)
  //       // console.log('123', this.message)
  //       this.showToastMessages()
  //     });
  //     socket.on('readNotif', (args) => {
  //       console.log('socketRead', args)
  //       this.totalNotif = args.totalNotif
  //       // console.log('123', this.message)
  //       // this.showToastMessages()
  //     });
  //   } 
  //   this.getTotalNotif()
  // },
  async mounted() {
    
console.log('topbar')
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    this.activateParentDropdown();
    console.log('store',this.$store.state.authfack.user.id);
    this.$router.afterEach(() => {
      this.activateParentDropdown();
    });
    this.getTotalNotif()
    const loggeduser = localStorage.getItem('user');
    const jsonUser = JSON.parse(loggeduser)
    this.userName = jsonUser.username
// socket page
    this.$socket.emit('pageOpened', {
        usertId:'12345',
        userId: this.$store.state.authfack.user.id,
        page:'HomePage'
      });
    
     // Fungsi untuk mengirim status "leave" saat semua tab ditutup
// Fungsi untuk mengirim status "leave" saat semua tab ditutup
    const sendLeavePageStatus = async () => {
        try {
            await axios.post(process.env.VUE_APP_URL_FEATHERS + 'leave-page-status?closeStatus=false');
        } catch (error) {
            console.error('Error sending leave page status:', error);
        }
    };

    // Fungsi untuk mengirim status "open" saat tab pertama dibuka
    const sendOpenPageStatus = async () => {
        try {
            await axios.post(process.env.VUE_APP_URL_FEATHERS + 'leave-page-status?closeStatus=true').then(async () => {
                return await this.getOnlineAdmin().then((response) => {
                    this.countOnlineAdmin = response.data.total;
                    this.dataLastActivity = response.data;
                });
            });
        } catch (error) {
            console.error('Error sending open page status:', error);
        }
    };

// Membuat ID unik untuk tab ini menggunakan timestamp
const tabId = Date.now().toString();
sessionStorage.setItem("tabId", tabId);

// Fungsi untuk memperbarui daftar tab yang terbuka di localStorage
const updateTabList = () => {
    let openTabs = JSON.parse(localStorage.getItem("openTabs")) || [];
    if (!openTabs.includes(tabId)) {
        openTabs.push(tabId);
        localStorage.setItem("openTabs", JSON.stringify(openTabs));
    }
};

// Fungsi untuk menghapus tab dari daftar saat tab ditutup
const removeTab = () => {
    let openTabs = JSON.parse(localStorage.getItem("openTabs")) || [];
    openTabs = openTabs.filter(id => id !== tabId);
    localStorage.setItem("openTabs", JSON.stringify(openTabs));
};

// Event listener saat tab pertama kali dimuat
window.addEventListener("load", async () => {
    updateTabList();
    const openTabs = JSON.parse(localStorage.getItem("openTabs")) || [];
    if (openTabs.length === 1 ) { // Jika ini adalah tab pertama
        await sendOpenPageStatus();
    }
});

// Event listener untuk menangani penutupan tab
window.addEventListener("beforeunload", async () => {
    removeTab();
    const openTabs = JSON.parse(localStorage.getItem("openTabs")) || [];
    if (openTabs.length === 0) { // Jika ini adalah tab terakhir yang terbuka
        await sendLeavePageStatus();
    }
});

// Event listener untuk mendeteksi perubahan pada localStorage
window.addEventListener("storage", async () => {
    const openTabs = JSON.parse(localStorage.getItem("openTabs")) || [];
    if (openTabs.length === 0) { // Jika tidak ada tab yang terbuka
        await sendLeavePageStatus();
    }
});

    //document.addEventListener('visibilitychange', this.handleVisibilityChange);
    await this.getOnlineAdmin().then((response)=>{
                  this.countOnlineAdmin = response.data.total
                  this.dataLastActivity = response.data
                })
  },
  beforeDestroy() {
    this.pageClosed()
    this.$socket.disconnect()
   // document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    getNotif: async function (args) {
      // console.log('this method was fired by the getNotif server', args)
      this.totalNotif = args.totalNotif
      this.message.text = args.message
      this.message.from = args.profileName
      this.$root.$emit('chatSocket', args)
      this.showToastMessages()
      await this.getOnlineAdmin().then((response)=>{
        this.countOnlineAdmin = response.data.total
        this.dataLastActivity = response.data
        })
    },
    readNotif: function (args) {
      // console.log('this method was fired by the readNotif server', args)
      this.totalNotif = args.totalNotif
      this.$root.$emit('chatSocket', args)
      // console.log('123', this.message)
    },

    readOpened: async function (){
      console.log('testing Open')
      this.root.$emit('pageOpened', {
        usertId:'12345',
        userId: this.$store.state.authfack.user.id,
        page:'HomePage'
      })

      let a = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'leave-page-status')
      console.log(a);
      await this.getOnlineAdmin().then((response)=>{
        this.countOnlineAdmin = response.data.total
        this.dataLastActivity = response.data
        })
      window.addEventListener('beforeunload',()=>{

        this.root.$emit('pageClosed', {
        usertId:'12345',
        userId: this.$store.state.authfack.user.id,
        page:'HomePage'
      })
      })
     
    },
   
    updateTicket: async function (args) {
      // console.log('this method was fired by the getNotif server', args)
      console.log("socket inii",args);
      
      let text = ''
      if (args.stage == 'ticketSubmitted') {
        text = 'Ticket Dibuat'
      } else if (args.stage == 'ticketOnProgress') {
        text = 'Ticket Dalam Pengerjaan'
      } else if (args.stage == 'ticketProgressDone') {
        text = 'Pengerjaan Ticket Selesai'
      } else if (args.stage == 'ticketDone') {
        text = 'Ticket Selesai'
      } else if (args.isDeleted == true){
        text = 'Ticket Di Hapus'
      }
      let url = "ticket-notif-dashboard?text=" + args.ticketNo
      console.log("args",text);
      
      let a = await axios.get(process.env.VUE_APP_URL_FEATHERS + url)
      console.log("socket", a);
      this.message.text = args.ticketNo
      this.message.from = text

      this.$root.$emit('chatSocket', args)


      // console.log('123', this.message)
      this.showToastMessagesTicket()
    }

  },
  methods: {
    async submitSearch() {
      // console.log('asd')
      if (this.searchValue == '') {
        Swal.fire("Gagal!", "Isian tidak boleh kosong!", "error");
      } else {
        if (_.startsWith(this.searchValue, '08') || _.startsWith(this.searchValue, '62')) {
          // console.log('no hp')
          await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-invoices?phonenumber=' + this.searchValue)
            .then(response => {
              if (response.data.status == 'PHONE_NUMBER_NOT_FOUND ' || response.data.status == 0) {
                Swal.fire("Maaf!", "Data tidak ditemukan!", "error");
              } else {
                const id = response.data[0].customerId
                this.$router.replace('/customer/' + response.data[0].customerId).catch((error1) => {
                  console.log('error', error1)
                  if (this.$route.path !== '') {
                  this.$router.push('/customer/' + response.data[0].customerId);
                }
                  this.$router.replace('/').then(() => {
                    this.$router.replace('/customer/' + id)
                  })
                })
                // console.log(response.data[0].customerId)
              }
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        } else {
          await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-product-search?orderId=' + this.searchValue)
            .then(response => {
              if (response.data.length == 0) {
                Swal.fire("Maaf!", "Data tidak ditemukan!", "error");
              } else {
                this.$router.replace('/customer/' + response.data[0].customerId)

                if (this.$route.path !== '') {
                  this.$router.push('/customer/' + response.data[0].customerId);
                }
                this.$router.replace('/').then(() => {
                  this.$router.replace('/customer/' + response.data[0].customerId)
                })

                // console.log(response.data[0].customerId)
              }
            })
            .catch(error => {
              Swal.fire("Gagal!", error.message, "error");
              console.error("There was an error!", error);
            });
        }
      }
    },
    async loadSearch(value) {
      if (_.startsWith(value, '08') || _.startsWith(value, '62')) {
        // console.log('no hp')
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-invoices?phonenumber=' + value)
          .then(response => {
            if (response.data.status == 'PHONE_NUMBER_NOT_FOUND ' || response.data.status == 0) {
              // console.log('tidak ada hp')
              this.isSearchFound = false
              // Swal.fire("Maaf!", "Data tidak ditemukan!", "error");
            } else {
              // this.$router.push('/customer/' + response.data[0].customerId)
              this.isSearchFound = true

              // console.log(response.data[0].customerId)
            }
          })
          .catch(error => {
            this.isSearchFound = false

            console.error("There was an error!", error);
          });
      } else {
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-product-search?orderId=' + value)
          .then(response => {
            if (response.data.length == 0) {
              // console.log('tidak ada orderId')
              this.isSearchFound = false

              // Swal.fire("Maaf!", "Data tidak ditemukan!", "error");
            } else {
              // this.$router.push('/customer/' + response.data[0].customerId)
              this.isSearchFound = true

              // console.log(response.data[0].customerId)
            }
          })
          .catch(error => {
            this.isSearchFound = false

            console.error("There was an error!", error);
          });
      }
    },
    async updateNotif(par) {
      this.totalNotif = par
      // console.log('updateNotif', this.totalNotif)
    },
    showToastMessages() {
      this.$toast.open({
        message: `<b> New Messages </b> <br> ${this.message.from} <br> ${this.message.text}`,
        type: "success",
        duration: 1000 * 10,
        dismissible: true
      })
    },

    showToastMessagesTicket() {
      this.$toast.open({
        message: `<b> Update Ticket </b> <br> ${this.message.from} <br> ${this.message.text}`,
        type: "success",
        duration: 1000 * 10,
        dismissible: true
      })
    },
    async getTotalNotif() {
      this.totalNotif = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-total')
        .then(response => {
          // console.log(response.data.total)
          return response.data.total
        })
      // console.log(this.totalNotif)          
    },
    async handleBeforeUnload(event) {
      console.log('cihuy',event)
      
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'user-setting')
    const confirmationMessage = 'Apakah Anda yakin ingin meninggalkan halaman ini?';
    
    event.preventDefault(); // Menghentikan aksi default
    event.returnValue = confirmationMessage; // Beberapa browser akan menampilkan pesan ini
    return confirmationMessage; // Untuk beberapa browser lain
      // Custom pesan (optional)
      // if (this.formChanged) {
      //   // Cegah tab dari ditutup langsung
      //   event.preventDefault();
      //   event.returnValue = '';
        
      //   // Tampilkan modal custom
      //   
      //   return ''; // Memicu dialog browser standar
      // }
    },
    // handleVisibilityChange() {
    // if (document.visibilityState === 'hidden') {
    //   // Lakukan tindakan sebelum tab ditutup, misalnya menyimpan data
    //   console.log('Tab tidak terlihat.');
    //  // Contoh fungsi untuk menyimpan data
    // } else {
    //   console.log('Tab terlihat kembali.');
    // }
    // }, 
   async pageClosed() {
    
    
      // console.log('currentTabs',localStorage);
    
      this.$socket.emit('pageClosed', {
        usertId:'12345',
        page:'HomePage'
      })
     
      // return  await axios.post(process.env.VUE_APP_URL_FEATHERS + 'leave-page-status')
      
    },
  async getOnlineAdmin (){
      return await axios.get(process.env.VUE_APP_URL_FEATHERS + 'leave-page-status?status=true')
    },
    async showUserOnline() {
      await this.getOnlineAdmin(); // Mendapatkan data pengguna online
      this.openModalActive()
      console.log(this.dataLastActivity);

      // Buat HTML dinamis berdasarkan array `dataLastActivity`
      // const userHtml = this.dataLastActivity.data.map(data => {
      //   let timeAgoText = "Tidak tersedia";
        
      //   if (data.lastActivity) {
      //     const lastActivityDate = new Date(data.lastActivity);
      //     if (!isNaN(lastActivityDate)) { // Cek apakah tanggal valid
      //       const now = new Date();
      //       const diffMs = now - lastActivityDate; // Selisih waktu dalam milidetik
      //       const diffMins = Math.floor(diffMs / 60000); // Konversi ke menit

      //       if (diffMins < 1) {
      //         timeAgoText = "Baru saja online";
      //       } else if (diffMins < 60) {
      //         timeAgoText = `${diffMins} menit yang lalu`;
      //       } else {
      //         const diffHours = Math.floor(diffMins / 60);
      //         timeAgoText = `${diffHours} jam yang lalu`;
      //       }
      //     }
      //   }

      //   return `
      //   <div class="row"> 
      //     <div class="col-5"> 
      //        <p>${data.name || 'User'}</p>
      //     </div>
      //     <div class="col-5"> 
      //        <p> ${timeAgoText}</p>
      //     </div> 
      //   </div>
          
      //   `;
      // }).join(''); // Gabungkan elemen array menjadi satu string HTML

      // // Tampilkan SweetAlert dengan konten HTML yang telah dibuat
      // Swal.fire({
      //   html: userHtml,
      //   width: 800,
      //   heightAuto: false, // Nonaktifkan auto-height
      //   showConfirmButton: false,
      //   customClass: {
      //     popup: 'no-scroll-popup'
      //   },
      //   didOpen: () => {
      //     const popup = Swal.getPopup();
      //     popup.style.overflowY = 'auto'; // Mengatur scroll vertikal saja jika diperlukan
      //   }
      // });
    },
    async openModalActive(){
      this.$refs.modalActive.show()
    },


    ...authFackMethods,
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },

    activateParentDropdown() {
      const resetParent = (el) => {
        const parent = el.parentElement;
        this._removeAllClass("mm-active");
        this._removeAllClass("mm-show");
        if (parent) {
          parent.classList.remove("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.remove("active");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove("active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove("active");
                const parent5 = parent4.parentElement;
                if (parent5) {
                  parent5.classList.remove("active");
                  const menuelement = document.getElementById(
                    "topnav-menu-content"
                  );
                  if (menuelement !== null)
                    if (menuelement.classList.contains("show"))
                      document
                        .getElementById("topnav-menu-content")
                        .classList.remove("show");
                }
              }
            }
          }
        }
      };
      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      for (let i = 0; i < links.length; i++) {
        // reset menu
        resetParent(links[i]);
      }
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }
      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;
        if (parent) {
          parent.classList.add("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add("active");
          }
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            var childAnchor = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("active");
          }

          const parent4 = parent3.parentElement;
          if (parent4) parent4.classList.add("active");
          const parent5 = parent4.parentElement;
          if (parent5) parent5.classList.add("active");
        }
      }
    },
    /**
     * Full-screen
     */
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle right-sidebar
     */

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextElementSibling;
      if (nextEl) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.toggle("show");
      }
      return false;
    },

    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * Language set
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    logoutUser() {
      this.logout();
      this.$router.push({
        path: "/account/login",
      });
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },


  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
    searchValue: {
      immediate: true,
      handler(newVal) {

        this.searchValue = newVal.replace(/\W/g, "");
        if (newVal !== '' && newVal.length >= 9) {
          this.loadSearch(newVal)

        }
      },
    },
  },
};
</script>

<template>
  
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/crmjdn_home.png" alt height="20" />
            </span>
          </router-link>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse"
          data-target="#topnav-menu-content" @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form @submit.prevent="submitSearch" class="app-search d-none d-lg-block">
          <div class="form-group m-0">
            <div class="input-group sizeSearch">
              <input type="text" class="form-control " placeholder="Ketik Order ID atau No HP Customer"
                v-model="searchValue" @keydown="nameKeydown($event)" />
              <span v-if="isSearchFound == null" class="fas fa-search"></span>
              <span v-if="isSearchFound" style="color:green" class="fas fa-check"></span>
              <span v-if="isSearchFound == false" style="color:red" class="fas fa-times"></span>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex">
        <b-dropdown variant="white" class="d-inline-block d-lg-none ms-2" toggle-class="header-item noti-icon" right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end">
          <template v-slot:button-content>
            <i class="uil-search"></i>
          </template>
          <form @submit.prevent="submitSearch" class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Ketik Order ID atau No HP Customer"
                  aria-label="Recipient's username" v-model="searchValue" keydown="nameKeydown($event)" />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i v-if="isSearchFound == null" class="fas fa-search"></i>
                    <i v-if="isSearchFound" class="fas fa-check"></i>
                    <i v-if="isSearchFound == false" style="color:red" class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <!-- <form @submit.prevent="submitSearch" class="app-search d-lg-block">
          <div class="form-group m-0">
            <div class="input-group sizeSearch">
              <input
                type="text"
                class="form-control "
                placeholder="Ketik Order ID atau No HP Customer"
                v-model="searchValue"
                @keydown="nameKeydown($event)"
              />
              <span v-if="isSearchFound==null" class="fas fa-search"></span>
              <span v-if="isSearchFound" style="color:green" class="fas fa-check"></span>
              <span v-if="isSearchFound==false" style="color:red" class="fas fa-times"></span>
            </div>
          </div>
        </form> -->
        </b-dropdown>

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="uil-minus-path"></i>
          </button>
        </div> -->
        <!-- <b-dropdown
          variant="white"
          class="dropdown d-inline-block"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="far fa-envelope"></i>
            <span v-if="totalNotif!=0" class="badge bg-danger rounded-pill">{{ totalNotif }}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h5>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px" data-simplebar>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="uil-shopping-basket"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.james.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.james.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.james.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="uil-truck"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.item.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.item.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.item.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.salena.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.salena.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.salena.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <div class="d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 text-center"
                href="javascript:void(0)"
              >
                <i class="uil-arrow-circle-right me-1"></i>
                {{ $t("navbar.dropdown.notification.button") }}
              </a>
            </div>
          </div>
        </b-dropdown> -->
        <div class="mb-0 mt-4 ml-3" v-if ="countOnlineAdmin !=0" style="margin-left: 9px" @click="showUserOnline" variant="link"> <span class= "fas fa-circle font-size-12 rounded-pill" style="color:#21e00b;  padding: 5px 10px; border: 1px solid #21e00b; "> <span style="font-family: 'IBM Plex Sans', sans-serif;" > {{ countOnlineAdmin }} Admin Active</span>
        </span></div>

        <b-dropdown class="d-inline-block" toggle-class="header-item" right variant="white">
          <template v-slot:button-content>
            <img class="rounded-circle header-profile-user" src="@/assets/images/users/default-avatar.png"
              alt="Header Avatar" />
            <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{ userName }}</span>
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

          <!-- item-->
          <router-link class="dropdown-item" to="/user-setting">
            <i class="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.settings")
            }}</span>

          </router-link>
          <a class="dropdown-item" href="javascript: void(0);" @click="logoutUser">
            <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.marcus.list.logout")
            }}</span>
          </a>
        </b-dropdown>
        <!-- gambar gear -->
        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
           
          >
            <i class="uil-cog toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>

    <div class="container-fluid">
      <div class="topnav">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
          <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav">
              <li class="nav-item dropdown" v-for="(item, index) of menuItems" :key="index">
                <router-link v-if="!item.subItems" :to="item.link"
                  class="nav-link dropdown-toggle arrow-none side-nav-link-ref">

                  <i v-if="item.id == 86 && totalNotif != 0" :class="`${item.icon} me-2 notif-icon`"> <span
                      class="badge bg-danger rounded-pill">{{ totalNotif }}</span> </i>
                  <i v-if="item.id == 86 && totalNotif == 0" :class="`${item.icon} me-2`"> </i>
                  <i v-if="item.id != 86" :class="`${item.icon} me-2`"> </i>
                  {{ $t(item.label) }}
                </router-link>

                <a v-if="item.subItems" class="nav-link dropdown-toggle arrow-none" @click="onMenuClick"
                  href="javascript: void(0);" id="topnav-components" role="button">
                  <i :class="`${item.icon} me-2`"></i>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>
                <div class="dropdown-menu" aria-labelledby="topnav-dashboard" v-if="hasItems(item)" :class="{
                  'dropdown-mega-menu-xl px-2': item.subItems.length > 11,
                }">
                  <template v-for="(subitem, index) of item.subItems">
                    <router-link :key="index" class="col dropdown-item side-nav-link-ref"
                      v-if="item.subItems.length < 11 && !hasItems(subitem)" :to="subitem.link">{{ $t(subitem.label)
                      }}</router-link>
                    <div v-if="item.subItems.length > 11" :key="index">
                      <div v-if="index % 3 == 0" class="row">
                        <div class="col-lg-4">
                          <router-link class="dropdown-item side-nav-link-ref" :to="subitem.link">{{
                            $t(item.subItems[index].label) }}</router-link>
                        </div>
                        <div class="col-lg-4" v-if="item.subItems[index + 1]">
                          <router-link class="dropdown-item side-nav-link-ref" :to="item.subItems[index + 1].link">{{
                            $t(item.subItems[index + 1].label)
                          }}</router-link>
                        </div>
                        <div class="col-lg-4" v-if="item.subItems[index + 2]">
                          <router-link class="dropdown-item side-nav-link-ref" :to="item.subItems[index + 2].link">{{
                            $t(item.subItems[index + 2].label)
                          }}</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                        {{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <router-link v-for="(subSubitem, index) of subitem.subItems" :key="index" :to="subSubitem.link"
                          class="dropdown-item side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <modalLastActive  ref="modalActive"></modalLastActive>
  </header>

  
</template>

<style>
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.v-toast--fade-out {
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.v-toast--fade-in-down {
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.v-toast--fade-in-up {
  animation-name: fadeInUp;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-toast {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 1052;
  pointer-events: none;
}

.v-toast__item {
  display: inline-flex;
  align-items: center;
  animation-duration: 150ms;
  margin: 0.5em 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 0.25em;
  pointer-events: auto;
  opacity: 0.92;
  color: #e7a200;
  min-height: 7em;
  height: 7em;
  min-width: 300px;
  cursor: pointer;
}

.v-toast__item--success {
  background-color: #ffffff;
}

.v-toast__item--info {
  background-color: #1c85d5;
}

.v-toast__item--warning {
  background-color: #febc22;
}

.v-toast__item--error {
  background-color: #f7471c;
}

.v-toast__item--default {
  background-color: #343a40;
}

.v-toast__item.v-toast__item--top,
.v-toast__item.v-toast__item--bottom {
  align-self: center;
}

.v-toast__item.v-toast__item--top-right,
.v-toast__item.v-toast__item--bottom-right {
  align-self: flex-end;
}

.v-toast__item.v-toast__item--top-left,
.v-toast__item.v-toast__item--bottom-left {
  align-self: flex-start;
}

.v-toast__text {
  margin: 0;
  padding: 0.5em 1em;
  word-break: break-word;
}

.v-toast__icon {
  display: none;
}

.v-toast.v-toast--top {
  flex-direction: column;
}

.v-toast.v-toast--bottom {
  flex-direction: column-reverse;
}

.v-toast.v-toast--custom-parent {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .v-toast {
    padding: 0;
    position: fixed !important;
  }
}

.v-toast__item {
  opacity: 1;
  min-height: 4em;
}

.v-toast__item .v-toast__text {
  padding: 1.5em 1em;
}

.v-toast__item .v-toast__icon {
  display: block;
  width: 27px;
  min-width: 27px;
  height: 27px;
  margin-left: 1em;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45.999 45.999'%3e %3cpath fill='%23555' d='M39.264 6.736c-8.982-8.981-23.545-8.982-32.528 0-8.982 8.982-8.981 23.545 0 32.528 8.982 8.98 23.545 8.981 32.528 0 8.981-8.983 8.98-23.545 0-32.528zM25.999 33a3 3 0 11-6 0V21a3 3 0 116 0v12zm-3.053-17.128c-1.728 0-2.88-1.224-2.844-2.735-.036-1.584 1.116-2.771 2.879-2.771 1.764 0 2.88 1.188 2.917 2.771-.001 1.511-1.152 2.735-2.952 2.735z'/%3e %3c/svg%3e") no-repeat;
}

.v-toast__item.v-toast__item--success .v-toast__icon {
  background: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20id%3D%22_24x24_On_Light_Messages-Alert%22%20data-name%3D%2224x24%2FOn%20Light%2FMessages-Alert%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000000%22%3E%3Cg%20id%3D%22SVGRepo_bgCarrier%22%20stroke-width%3D%220%22%3E%3C%2Fg%3E%3Cg%20id%3D%22SVGRepo_tracerCarrier%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C%2Fg%3E%3Cg%20id%3D%22SVGRepo_iconCarrier%22%3E%20%3Crect%20id%3D%22view-box%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%3E%3C%2Frect%3E%20%3Cpath%20id%3D%22Shape%22%20d%3D%22M10.751%2C19.5a9.66%2C9.66%2C0%2C0%2C1-4.266-.981%2C9.889%2C9.889%2C0%2C0%2C1-4.876.981c-.279%2C0-.578-.006-.887-.018a.74.74%2C0%2C0%2C1-.65-.432.738.738%2C0%2C0%2C1%2C.085-.775%2C11.191%2C11.191%2C0%2C0%2C0%2C2.072-3.787A9.754%2C9.754%2C0%2C0%2C1%2C12.682.192a5.478%2C5.478%2C0%2C0%2C0-.676%2C1.4A8.252%2C8.252%2C0%2C0%2C0%2C3.668%2C13.983a.75.75%2C0%2C0%2C1%2C.092.535A10.189%2C10.189%2C0%2C0%2C1%2C2.2%2C17.99a7.2%2C7.2%2C0%2C0%2C0%2C3.816-.947.746.746%2C0%2C0%2C1%2C.431-.136A.755.755%2C0%2C0%2C1%2C6.808%2C17a8.254%2C8.254%2C0%2C0%2C0%2C12.1-8.5%2C5.477%2C5.477%2C0%2C0%2C0%2C1.4-.676A9.755%2C9.755%2C0%2C0%2C1%2C10.751%2C19.5Zm3-7h-7a.75.75%2C0%2C0%2C1%2C0-1.5h7a.75.75%2C0%2C0%2C1%2C0%2C1.5Zm-2-4h-5a.75.75%2C0%2C1%2C1%2C0-1.5h5a.75.75%2C0%2C0%2C1%2C0%2C1.5Zm6.612-1.931h0a8.34%2C8.34%2C0%2C0%2C0-4.43-4.43%2C3.527%2C3.527%2C0%2C0%2C1%2C.781-1.3%2C9.773%2C9.773%2C0%2C0%2C1%2C4.946%2C4.946%2C3.527%2C3.527%2C0%2C0%2C1-1.3.781Z%22%20transform%3D%22translate(1.249%202.25)%22%20fill%3D%22%23e7a200%22%3E%3C%2Fpath%3E%20%3Cpath%20id%3D%22Shape-2%22%20data-name%3D%22Shape%22%20d%3D%22M3.5%2C7A3.5%2C3.5%2C0%2C1%2C1%2C7%2C3.5%2C3.5%2C3.5%2C0%2C0%2C1%2C3.5%2C7Z%22%20transform%3D%22translate(15%202)%22%20fill%3D%22%23ff3c2e%22%3E%3C%2Fpath%3E%20%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
}

.v-toast__item.v-toast__item--error .v-toast__icon {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.976 51.976'%3e %3cpath fill='%23fff' d='M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 01-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 11-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 112.828-2.828l7.425 7.425 7.071-7.071a2 2 0 112.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 010 2.828z'/%3e %3c/svg%3e") no-repeat;
}

.v-toast__item.v-toast__item--warning .v-toast__icon {
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill='%23fff' d='M49.466 41.26L29.216 6.85c-.69-1.16-1.89-1.85-3.22-1.85-1.32 0-2.53.69-3.21 1.85L2.536 41.26c-.71 1.2-.72 2.64-.03 3.85.68 1.18 1.89 1.89 3.24 1.89h40.51c1.35 0 2.56-.71 3.23-1.89.7-1.21.69-2.65-.02-3.85zm-25.53-21.405h3.381v3.187l-.724 8.92H24.66l-.725-8.92v-3.187zm2.97 17.344a1.712 1.712 0 01-1.267.543c-.491 0-.914-.181-1.268-.543a1.788 1.788 0 01-.531-1.297c0-.502.176-.935.53-1.297a1.712 1.712 0 011.269-.544c.49 0 .914.181 1.268.544s.53.795.53 1.297c0 .503-.176.934-.53 1.297z'/%3e %3c/svg%3e") no-repeat;
}

.sizeSearch {
  width: 300px;
}

.valid-feedback.feedback-icon,
.iconnya {
  position: absolute;
  width: auto;
  bottom: 10px;
  right: 10px;
  margin-top: 0;
}

.no-scroll-popup {
  max-height: 70vh; /* Mengatur tinggi maksimum menjadi 70% dari tinggi viewport */
  overflow-y: auto;  /* Menambahkan scroll hanya jika diperlukan */
}
</style>