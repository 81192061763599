<script>
import axios from "axios";
import Switches from "vue-switches";

export default {
    props: [],
    components: { Switches },
    mounted() { },

    data() {
        return {
            countOnlineAdmin: 0,
            dataLastActivity: null,
            dataReady: false,
            isChecked: true,
        };
    },

    methods: {
        async close() {
            this.$refs["lastActive"].hide();
        },

        async show() {
            this.dataReady = false;
            const response = await this.getOnlineAdmin();
            this.countOnlineAdmin = response.data.total;
            this.dataLastActivity = response.data.data;

            this.dataLastActivity.forEach((data) => {
                // Perbaikan di sini
                let timeAgoText = "Tidak tersedia";

                if (data.lastActivity) {
                    const lastActivityDate = new Date(data.lastActivity);
                    if (!isNaN(lastActivityDate)) {
                        // Cek apakah tanggal valid
                        const now = new Date();
                        const diffMs = now - lastActivityDate; // Selisih waktu dalam milidetik
                        const diffMins = Math.floor(diffMs / 60000); // Konversi ke menit

                        if (diffMins < 1) {
                            timeAgoText = "Baru saja ";
                        } else if (diffMins < 60) {
                            timeAgoText = `${diffMins} menit yang lalu`;
                        } else {
                            const diffHours = Math.floor(diffMins / 60);
                            timeAgoText = `${diffHours} jam yang lalu`;
                        }
                    }
                }
                data.lastActivity = timeAgoText; // Menyimpan hasil
            });

            console.log("response", this.dataLastActivity);
            this.$refs["lastActive"].show();
            this.dataReady = true;
        },

        async getOnlineAdmin() {
            return await axios.get(
                process.env.VUE_APP_URL_FEATHERS + "leave-page-status?status=true"
            );
        },
    },

    watch: {},
};
</script>

<template>
    <b-modal id="lastActive" ref="lastActive" size="md">
        <template #modal-header>
            <div class="d-none d-xl-inline-block ms-1 mt-0 fw-medium font-size-18">
                List Admin Online
            </div>
        </template>
        <div v-if="dataReady">
            <div v-for="user in dataLastActivity" :key="user._id">
                <div class="row align-items-center" style="height: 100%;"> <!-- Menambahkan height jika diperlukan -->
    <div class="col-auto">
        <!-- Menggunakan col-auto untuk ukuran kolom yang lebih fleksibel -->
        <img class="rounded-circle header-profile-user" src="@/assets/images/users/default-avatar.png"
            alt="Header Avatar" style="width: 45px; height: 45px;" />
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-start">
        <!-- Menggunakan col agar mengambil sisa ruang yang ada -->
        <div class="row">
            <span class="d-xl-inline-block ms-1 p-0 mt-0 fw-medium font-size-15">{{ user.name }}</span>
        </div>
        <div class="row">
            <span class="badge bg-success " style="display: inline-block; width: auto; padding: 3.5px; white-space: nowrap; ">
    Aktif Terakhir Pada: {{ user.lastActivity }}
</span>
        </div>
    </div>
</div>

            </div>
        </div>

        <template #modal-footer>
            <div class="justify-content-between w-100 mb-1 d-flex flex-column" style="height: 100%">
                <!-- Accordion dengan ikon gear sebagai trigger -->
                <div class="row flex-grow-1">
                    <div class="col-6 d-flex align-items-center">
                        <div class="text-truncate md">
                            <span class="fw-medium">Enable Ai Auto Reply</span>
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end p-0">
                        <switches v-model="isChecked" type-bold="true" :color="isChecked ? 'success' : 'danger'"
                            class="mb-0 mt-1 pt-0 custom-switch-ucup"></switches>
                    </div>
                </div>
                <div class="row flex-grow-1">
                    <div class="col-6 d-flex align-items-center">
                        <div class="text-truncate md">
                            <span class="fw-medium">Enable Ai Suggestion Chat</span>
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end p-0">
                        <switches v-model="isChecked" type-bold="true" :color="isChecked ? 'success' : 'danger'"
                            class="mb-0 mt-1 pt-0 custom-switch-ucup"></switches>
                    </div>
                </div>
                <!-- Tombol Ok di sebelah kanan bawah -->
                <div class="d-flex justify-content-end" style="border-top: 1px; padding-top: 12px">
                    <b-button size="md" variant="primary" @click="close()" style="
              max-width: 120px;
              min-width: 50px;
              max-height: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            ">Ok</b-button>
                </div>
            </div>

            <!-- Konten Accordion -->
        </template>
    </b-modal>
</template>

<style>
.custom-switch-ucup {
    transform: scale(0.65);
    /* Sesuaikan nilai skala sesuai kebutuhan */
}
</style>
